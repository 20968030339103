import { Pipe, PipeTransform } from '@angular/core';
import { ILanguage } from '../../../shared/models/language.interface';

@Pipe({
  name: 'pharLanguage',
  standalone: true,
})

export class LanguagePipe implements PipeTransform {
  transform(languageId: number, languages: ILanguage[]): any {
    const targetLanguage = languages.find((language: ILanguage) => language.id === languageId);
    return targetLanguage ? targetLanguage.name : languageId;
  }
}
